import x from "classnames";
import { HStack as y, WarningOutlineIcon as F } from "native-base";
import { memo as C, forwardRef as R, useState as _, useId as N, useCallback as r, useRef as S } from "react";
import { jsxs as a, jsx as t, Fragment as Z } from "react/jsx-runtime";
import $ from "react-dom";
const B = (e) => /* @__PURE__ */ a("svg", {
  fill: "none",
  height: 20,
  viewBox: "0 0 20 20",
  width: 20,
  ...e,
  children: [/* @__PURE__ */ t("path", {
    d: "M17.725 7.65c-.242-.383-.5-.742-.767-1.075a.834.834 0 0 0-1.241-.075l-2.5 2.5c.183.55.216 1.183.05 1.842a3.35 3.35 0 0 1-2.417 2.416 3.267 3.267 0 0 1-1.842-.05l-2.05 2.05c-.416.417-.283 1.15.275 1.367a7.683 7.683 0 0 0 2.767.517c1.483 0 2.925-.434 4.242-1.242 1.341-.833 2.55-2.058 3.525-3.617.791-1.258.75-3.375-.042-4.633Zm-6.042.667-3.366 3.366a2.418 2.418 0 0 1-.7-1.683A2.388 2.388 0 0 1 10 7.617c.65 0 1.25.275 1.683.7Z",
    fill: "currentColor"
  }), /* @__PURE__ */ t("path", {
    d: "m15.208 4.792-2.825 2.825A3.322 3.322 0 0 0 10 6.633 3.363 3.363 0 0 0 6.633 10c0 .933.375 1.775.984 2.383L4.8 15.208h-.008c-.925-.75-1.775-1.708-2.5-2.841-.834-1.309-.834-3.434 0-4.742.966-1.517 2.15-2.708 3.466-3.525C7.075 3.3 8.517 2.858 10 2.858c1.858 0 3.658.684 5.208 1.934ZM12.383 10A2.388 2.388 0 0 1 10 12.383c-.05 0-.092 0-.142-.016l2.509-2.509c.016.05.016.092.016.142Z",
    fill: "currentColor"
  }), /* @__PURE__ */ t("path", {
    d: "M18.142 1.858a.644.644 0 0 0-.909 0L1.858 17.242a.644.644 0 0 0 0 .908.632.632 0 0 0 .9-.008L18.142 2.758a.62.62 0 0 0 0-.9Z",
    fill: "currentColor"
  })]
}), k = (e) => /* @__PURE__ */ a("svg", {
  fill: "none",
  height: 20,
  viewBox: "0 0 20 20",
  width: 20,
  ...e,
  children: [/* @__PURE__ */ t("path", {
    d: "M17.708 7.625C15.783 4.6 12.967 2.858 10 2.858c-1.483 0-2.925.434-4.242 1.242-1.316.817-2.5 2.008-3.466 3.525-.834 1.308-.834 3.433 0 4.742C4.217 15.4 7.033 17.133 10 17.133c1.483 0 2.925-.433 4.242-1.241 1.316-.817 2.5-2.009 3.466-3.525.834-1.3.834-3.434 0-4.742ZM10 13.367A3.363 3.363 0 0 1 6.633 10c0-1.858 1.5-3.367 3.367-3.367A3.363 3.363 0 0 1 13.367 10c0 1.858-1.5 3.367-3.367 3.367Z",
    fill: "currentColor"
  }), /* @__PURE__ */ t("path", {
    d: "M10 7.617a2.38 2.38 0 0 0 0 4.758A2.386 2.386 0 0 0 12.383 10 2.394 2.394 0 0 0 10 7.617Z",
    fill: "currentColor"
  })]
}), V = "_container_phfrw_1", E = "_label_phfrw_6", L = "_input_phfrw_22", H = "_containerFocused_phfrw_38", P = "_containerWithValue_phfrw_48", T = "_button_phfrw_53", h = {
  container: V,
  label: E,
  input: L,
  containerFocused: H,
  containerWithValue: P,
  button: T
}, j = /* @__PURE__ */ C(/* @__PURE__ */ R(({
  autoFocus: e = !1,
  error: o,
  name: l,
  onBlur: s,
  onChange: c,
  onPressEnter: u,
  placeholder: f,
  type: d = "text",
  value: i
}, m) => {
  const [b, w] = _(!1), [v, g] = _(!1), I = N(), A = r(() => {
    g(!0);
  }, []), W = r(() => {
    g(!1), s();
  }, [s]), M = r(() => {
    w((p) => !p);
  }, []);
  return /* @__PURE__ */ a(Z, {
    children: [/* @__PURE__ */ a("div", {
      className: x(h.container, {
        [h.containerFocused]: v,
        [h.containerWithValue]: !!i && !v
      }),
      children: [/* @__PURE__ */ t("label", {
        className: h.label,
        htmlFor: `${l}_${I}`,
        children: f
      }), /* @__PURE__ */ t("input", {
        ref: m,
        autoFocus: e,
        className: h.input,
        id: `${l}_${I}`,
        name: l,
        onBlur: W,
        onChange: (p) => {
          c(p.target.value);
        },
        onFocus: A,
        onKeyDown: (p) => {
          u != null && p.key === "Enter" && u();
        },
        type: b ? "text" : d,
        value: i ?? ""
      }), d === "password" ? /* @__PURE__ */ t("button", {
        className: h.button,
        onClick: M,
        type: "button",
        children: b ? /* @__PURE__ */ t(B, {}) : /* @__PURE__ */ t(k, {})
      }) : null]
    }), o != null && /* @__PURE__ */ a(y, {
      alignItems: "center",
      color: "red.50",
      mb: 4,
      ml: 4,
      mt: -3,
      space: 3,
      children: [/* @__PURE__ */ t(F, {
        color: "red.50",
        size: "xs"
      }), o.message]
    })]
  });
}));
j.displayName = "FormInputWeb";
const D = "_title_12ytn_1", O = {
  title: D
}, q = (e) => /* @__PURE__ */ t("h1", {
  className: O.title,
  ...e
});
q.displayName = "FormTitleWeb";
const z = /* @__PURE__ */ C(({
  children: e,
  selector: o
}) => {
  const l = document.querySelector(o ?? "body");
  return l ? /* @__PURE__ */ $.createPortal(e, l) : null;
});
z.displayName = "PortalContainer";
const K = (e) => /* @__PURE__ */ t("svg", {
  fill: "none",
  height: 20,
  viewBox: "0 0 20 20",
  width: 20,
  ...e,
  children: /* @__PURE__ */ t("path", {
    d: "M16.837 5.437 9.8 15.415A1.416 1.416 0 0 1 8.638 16a1.45 1.45 0 0 1-1.078-.47l-4.328-4.788c-.346-.383-.3-.944.105-1.28.393-.325 1.054-.332 1.456-.012l3.678 2.919 6.582-7.986c.327-.398 1.006-.505 1.47-.23.483.285.62.85.314 1.284Z",
    fill: "currentColor"
  })
}), G = (e) => /* @__PURE__ */ t("svg", {
  fill: "none",
  height: 10,
  viewBox: "0 0 10 10",
  width: 10,
  ...e,
  children: /* @__PURE__ */ t("path", {
    d: "m1 9 8-8M9 9 1 1",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: 2
  })
}), p1 = (e) => /* @__PURE__ */ t("svg", {
  fill: "none",
  height: 40,
  viewBox: "0 0 40 40",
  width: 40,
  ...e,
  children: /* @__PURE__ */ t("path", {
    clipRule: "evenodd",
    d: "M20 2.5C10.335 2.5 2.5 10.335 2.5 20S10.335 37.5 20 37.5 37.5 29.665 37.5 20 29.665 2.5 20 2.5Zm2.155 12.988c.65-.65 1.706-.65 2.357 0l3.333 3.334c.322.322.485.742.488 1.165v.027a1.664 1.664 0 0 1-.475 1.152l-.014.014-3.332 3.332a1.667 1.667 0 1 1-2.357-2.357l.488-.488h-9.31a1.667 1.667 0 0 1 0-3.334h9.31l-.488-.488a1.667 1.667 0 0 1 0-2.357Z",
    fill: "currentColor",
    fillRule: "evenodd"
  })
}), J = (e) => /* @__PURE__ */ t("svg", {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...e,
  children: /* @__PURE__ */ t("path", {
    d: "m8.707 19.707 8.586-8.586a1 1 0 0 0 0-1.414l-3-3a1 1 0 0 0-1.414 0l-8.586 8.586a1.003 1.003 0 0 0-.263.464l-.743 3.782a1 1 0 0 0 1.174 1.174l3.781-.743c.176-.044.337-.135.465-.263ZM21 7.414a2 2 0 0 0 0-2.828L19.414 3a2 2 0 0 0-2.828 0l-.879.879a1 1 0 0 0 0 1.414l3 3a1 1 0 0 0 1.414 0L21 7.414Z",
    fill: "currentColor"
  })
}), b1 = (e) => /* @__PURE__ */ t("svg", {
  fill: "none",
  height: 40,
  viewBox: "0 0 40 40",
  width: 40,
  ...e,
  children: /* @__PURE__ */ t("path", {
    clipRule: "evenodd",
    d: "M3.333 18.333c0-4.984 0-7.477 1.072-9.333a8 8 0 0 1 2.928-2.928C9.19 5 11.682 5 16.667 5 21.65 5 24.144 5 26 6.072A8 8 0 0 1 28.928 9c.504.872.77 1.885.912 3.23l.214-.157c2.677-1.967 4.016-2.951 5.131-2.905.971.04 1.875.526 2.468 1.326.68.919.68 2.622.68 6.028v6.956c0 3.406 0 5.11-.68 6.028a3.243 3.243 0 0 1-2.468 1.326c-1.115.046-2.454-.938-5.131-2.905l-.214-.157c-.141 1.345-.408 2.358-.912 3.23A8 8 0 0 1 26 33.928C24.144 35 21.651 35 16.667 35c-4.985 0-7.477 0-9.334-1.072A8 8 0 0 1 4.405 31c-1.072-1.856-1.072-4.349-1.072-9.333v-3.334Zm16.667 0a3.333 3.333 0 1 0 0-6.666 3.333 3.333 0 0 0 0 6.666Z",
    fill: "currentColor",
    fillRule: "evenodd"
  })
}), v1 = (e) => /* @__PURE__ */ t("svg", {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...e,
  children: /* @__PURE__ */ t("path", {
    clipRule: "evenodd",
    d: "M9.294 21c-1.656 0-3.23-.666-4.432-1.875-2.388-2.403-2.486-6.213-.22-8.494l7.386-7.43A4.085 4.085 0 0 1 14.95 2a4.48 4.48 0 0 1 3.192 1.349c1.721 1.731 1.788 4.482.146 6.132l-7.395 7.429a2.535 2.535 0 0 1-1.815.748 2.738 2.738 0 0 1-1.95-.821c-1.053-1.061-1.086-2.752-.073-3.772L13.88 6.21a.999.999 0 1 1 1.416 1.41l-6.823 6.856c-.24.242-.207.669.074.951a.76.76 0 0 0 .53.231.55.55 0 0 0 .399-.158l7.394-7.429c.867-.873.802-2.358-.146-3.312-.906-.912-2.446-.984-3.277-.148l-7.386 7.43c-1.494 1.503-1.396 4.049.22 5.674C7.104 18.544 8.174 19 9.294 19a3.648 3.648 0 0 0 2.61-1.064l7.387-7.43a1 1 0 1 1 1.418 1.41l-7.386 7.43A5.64 5.64 0 0 1 9.293 21Z",
    fill: "currentColor",
    fillRule: "evenodd"
  })
}), Q = (e) => /* @__PURE__ */ a("svg", {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...e,
  children: [/* @__PURE__ */ t("g", {
    clipPath: "url(#IconRefreshWeb)",
    children: /* @__PURE__ */ t("path", {
      d: "M23.61 14.5a.269.269 0 0 1-.016.11c-.667 2.791-2.063 5.054-4.188 6.788C17.281 23.133 14.792 24 11.938 24c-1.521 0-2.993-.287-4.415-.86a11.927 11.927 0 0 1-3.804-2.453l-2.016 2.016A.961.961 0 0 1 1 23a.961.961 0 0 1-.703-.297A.961.961 0 0 1 0 22v-7c0-.27.099-.505.297-.703A.961.961 0 0 1 1 14h7c.27 0 .505.099.703.297A.961.961 0 0 1 9 15c0 .27-.099.505-.297.703l-2.14 2.14a8.063 8.063 0 0 0 2.515 1.595A7.797 7.797 0 0 0 12 20a7.861 7.861 0 0 0 3.906-1.016 7.878 7.878 0 0 0 2.906-2.797c.115-.177.391-.786.829-1.828.083-.24.24-.359.468-.359h3a.48.48 0 0 1 .352.148.48.48 0 0 1 .148.352ZM24 2v7c0 .27-.099.505-.297.703A.961.961 0 0 1 23 10h-7a.961.961 0 0 1-.703-.297A.961.961 0 0 1 15 9c0-.27.099-.505.297-.703l2.156-2.156C15.912 4.714 14.093 4 12 4a7.862 7.862 0 0 0-3.906 1.016 7.878 7.878 0 0 0-2.906 2.796c-.115.178-.391.787-.829 1.829-.083.24-.24.359-.468.359H.78a.48.48 0 0 1-.351-.148A.48.48 0 0 1 .28 9.5v-.11C.958 6.6 2.365 4.337 4.5 2.603 6.635.867 9.135 0 12 0c1.52 0 3 .29 4.438.867a12.106 12.106 0 0 1 3.828 2.446l2.03-2.016A.961.961 0 0 1 23 1c.27 0 .505.099.703.297A.961.961 0 0 1 24 2Z",
      fill: "currentColor"
    })
  }), /* @__PURE__ */ t("defs", {
    children: /* @__PURE__ */ t("clipPath", {
      id: "IconRefreshWeb",
      children: /* @__PURE__ */ t("path", {
        d: "M0 0h24v24H0z",
        fill: "#fff"
      })
    })
  })]
}), _1 = (e) => /* @__PURE__ */ t("svg", {
  fill: "none",
  height: 40,
  viewBox: "0 0 40 40",
  width: 40,
  ...e,
  children: /* @__PURE__ */ t("path", {
    clipRule: "evenodd",
    d: "M13.333 2.5a2.5 2.5 0 0 0-2.5 2.5v1.284c-1.056.239-1.91.604-2.651 1.163a8 8 0 0 0-1.568 1.568C5 11.155 5 14.242 5 20.417c0 6.175 0 9.262 1.614 11.401a8.002 8.002 0 0 0 1.568 1.568C10.321 35 13.408 35 19.583 35h.834c6.175 0 9.262 0 11.401-1.614a8.004 8.004 0 0 0 1.568-1.568C35 29.679 35 26.592 35 20.417s0-9.263-1.614-11.402a8.002 8.002 0 0 0-1.568-1.568c-.74-.559-1.595-.924-2.651-1.163V5a2.5 2.5 0 0 0-5 0v.852c-1.108-.019-2.35-.019-3.75-.019h-.834c-1.4 0-2.642 0-3.75.02V5a2.5 2.5 0 0 0-2.5-2.5Zm.834 18.333a1.667 1.667 0 0 1 3.333 0V22.5h1.667a1.667 1.667 0 0 1 0 3.333H17.5V27.5a1.667 1.667 0 1 1-3.333 0v-1.667H12.5a1.667 1.667 0 1 1 0-3.333h1.667v-1.667Z",
    fill: "currentColor",
    fillRule: "evenodd"
  })
}), m1 = (e) => /* @__PURE__ */ t("svg", {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...e,
  children: /* @__PURE__ */ t("path", {
    clipRule: "evenodd",
    d: "m9.218 3.365 9.564 4.757c4.29 2.133 4.29 5.623 0 7.756l-9.564 4.757c-6.435 3.2-9.061.577-5.843-5.812l.447-.884a2 2 0 0 1 1.66-1.094l5.019-.314a.532.532 0 0 0 0-1.062l-5.022-.314a2 2 0 0 1-1.662-1.098l-.442-.88C.157 2.787 2.794.165 9.218 3.365Z",
    fill: "currentColor",
    fillRule: "evenodd"
  })
}), w1 = (e) => /* @__PURE__ */ t("svg", {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...e,
  children: /* @__PURE__ */ t("path", {
    clipRule: "evenodd",
    d: "M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11Zm0-2a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm4.093-6.127c-.452-.318-1.07-.196-1.489.164A3.988 3.988 0 0 1 12 16a3.988 3.988 0 0 1-2.604-.963c-.42-.36-1.037-.482-1.489-.164-.451.318-.565.949-.177 1.342A5.988 5.988 0 0 0 12 18c1.635 0 3.16-.66 4.27-1.785.388-.393.274-1.024-.177-1.342ZM15.5 11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3ZM7 9.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z",
    fill: "currentColor",
    fillRule: "evenodd"
  })
}), U = "_container_tf4wt_1", X = "_field_tf4wt_9", Y = "_input_tf4wt_14", t1 = "_inputFocus_tf4wt_39", e1 = "_focused_tf4wt_1", n1 = "_controls_tf4wt_45", l1 = "_controlsVisible_tf4wt_56", o1 = "_button_tf4wt_64", c1 = "_buttonSave_tf4wt_83", i1 = "_buttonClose_tf4wt_91", a1 = "_loading_tf4wt_99", n = {
  container: U,
  field: X,
  input: Y,
  inputFocus: t1,
  focused: e1,
  controls: n1,
  controlsVisible: l1,
  button: o1,
  buttonSave: c1,
  buttonClose: i1,
  loading: a1
}, s1 = /* @__PURE__ */ C(({
  defaultValue: e = "",
  loading: o,
  onSave: l
}) => {
  const s = S(null), [c, u] = _(e), [f, d] = _(!1), i = r(() => {
    d(!0), requestAnimationFrame(() => {
      s.current != null && s.current.focus();
    });
  }, [s]), m = r(() => {
    d(!1), u(e);
  }, [e]), b = r((v) => {
    u(v.target.value);
  }, []), w = r(async () => {
    c && c !== e && await l(c), d(!1);
  }, [e, c, l]);
  return /* @__PURE__ */ a("div", {
    className: n.container,
    children: [/* @__PURE__ */ t("div", {
      className: n.field,
      children: f ? /* @__PURE__ */ t("input", {
        ref: s,
        className: `${n.input} ${n.inputFocus}`,
        onChange: b,
        type: "text",
        value: c
      }) : /* @__PURE__ */ t("div", {
        className: n.input,
        onClick: i,
        onFocus: i,
        onKeyDown: i,
        onTouchStart: i,
        tabIndex: 0,
        children: c
      })
    }), /* @__PURE__ */ t("div", {
      className: `${n.controls} ${f ? n.controlsVisible : ""}`,
      children: f ? /* @__PURE__ */ a(Z, {
        children: [/* @__PURE__ */ t("button", {
          className: `${n.button} ${n.buttonSave} `,
          disabled: o,
          onClick: w,
          title: "Сохранить",
          type: "button",
          children: o ? /* @__PURE__ */ t(Q, {
            className: n.loading,
            height: "16",
            width: "16"
          }) : /* @__PURE__ */ t(K, {
            height: "24",
            width: "24"
          })
        }), /* @__PURE__ */ t("button", {
          className: `${n.button} ${n.buttonClose}`,
          disabled: o,
          onClick: m,
          title: "Отменить",
          type: "button",
          children: /* @__PURE__ */ t(G, {
            height: "14",
            width: "14"
          })
        })]
      }) : /* @__PURE__ */ t("button", {
        className: n.button,
        onClick: i,
        title: "Редактировать",
        type: "button",
        children: /* @__PURE__ */ t(J, {})
      })
    })]
  });
});
s1.displayName = "SettingsEditableInput";
export {
  j as FormInputWeb,
  q as FormTitleWeb,
  K as IconCheckWeb,
  G as IconCloseWeb,
  p1 as IconConnectWeb,
  J as IconEditWeb,
  B as IconEyeClosedWeb,
  k as IconEyeOpenedWeb,
  b1 as IconMeetingWeb,
  v1 as IconPaperClipWeb,
  Q as IconRefreshWeb,
  _1 as IconScheduleWeb,
  m1 as IconSendWeb,
  w1 as IconSmileWeb,
  z as PortalContainer,
  s1 as SettingsEditableInput
};
