import { I as n1, a as o1, b as d1, c as i1, d as a1, f as h1, e as v1, g as t1, h as r1 } from "./IconPlus-crg3RR_M.js";
import o, { Path as n, Rect as h, G as t, Defs as r, ClipPath as s, Circle as i } from "react-native-svg";
import { jsx as c, jsxs as d } from "react/jsx-runtime";
import * as Z from "react";
import { memo as a } from "react";
const B = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  viewBox: "0 0 32 32",
  ...l,
  children: /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M10.667 2a2 2 0 0 0-2 2v1.393A8 8 0 0 0 4.514 9.85C4 11.21 4 12.919 4 16.333c0 3.415 0 5.123.513 6.485a8 8 0 0 0 4.669 4.67c1.362.512 3.07.512 6.485.512h.666c3.415 0 5.123 0 6.485-.513a8 8 0 0 0 4.67-4.669c.512-1.362.512-3.07.512-6.485 0-3.414 0-5.122-.513-6.484a8 8 0 0 0-4.155-4.455l.001-.06V4a2 2 0 1 0-4 0v.688c-.831-.021-1.812-.021-3-.021h-.666c-1.188 0-2.169 0-3 .021V4a2 2 0 0 0-2-2ZM18 10.667a1.333 1.333 0 1 1 0 2.666h-8a1.333 1.333 0 1 1 0-2.666h8ZM14 16a1.333 1.333 0 0 1 0 2.667h-4A1.333 1.333 0 0 1 10 16h4Z",
    fill: e,
    fillRule: "evenodd"
  })
}), p = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...l,
  children: /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M22 9.5V16a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V9.5a4 4 0 0 1 4-4h.836a1 1 0 0 0 .707-.293l1.121-1.121a2 2 0 0 1 1.414-.586h4.094a2 2 0 0 1 1.414.586l1.121 1.121a1 1 0 0 0 .707.293H18a4 4 0 0 1 4 4ZM8.64 10.834a3.75 3.75 0 0 1 6.458-.448l-.403.179a.75.75 0 0 0 .01 1.374l1.75.75a.75.75 0 0 0 1.016-.483l.5-1.75a.75.75 0 0 0-1.026-.891l-.46.204a5.25 5.25 0 0 0-9.189.397.75.75 0 1 0 1.343.668Zm-1.034 1.498a.75.75 0 0 0-1.017.483l-.5 1.75a.75.75 0 0 0 1.026.891l.423-.188a5.25 5.25 0 0 0 9.128-.36.75.75 0 0 0-1.332-.689 3.75 3.75 0 0 1-6.407.432l.438-.195a.75.75 0 0 0-.009-1.374l-1.75-.75Z",
    fill: e,
    fillRule: "evenodd"
  })
}), g = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  viewBox: "0 0 28 28",
  ...l,
  children: [/* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M14 1.75C7.235 1.75 1.75 7.235 1.75 14S7.235 26.25 14 26.25 26.25 20.765 26.25 14 20.765 1.75 14 1.75ZM14 7a7 7 0 1 0 0 14 7 7 0 0 0 0-14Z",
    fill: e,
    fillRule: "evenodd"
  }), /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M14 9.333A4.667 4.667 0 1 0 18.667 14c0-.486-.7-.597-1.115-.343a2.333 2.333 0 0 1-3.209-3.209c.254-.414.143-1.115-.343-1.115Z",
    fill: e,
    fillRule: "evenodd"
  }), /* @__PURE__ */ c(h, {
    fill: e,
    height: 2.333,
    rx: 1.167,
    transform: "rotate(-45 .8 25.55)",
    width: 35,
    x: 0.801,
    y: 25.549
  })]
}), V = ({
  fill: e = "#F54522",
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  viewBox: "0 0 48 48",
  ...l,
  children: [/* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M24 3C12.402 3 3 12.402 3 24s9.402 21 21 21 21-9.402 21-21S35.598 3 24 3Zm0 9c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12Z",
    fill: e,
    fillRule: "evenodd"
  }), /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M24 16a8 8 0 1 0 8 8c0-.832-1.2-1.024-1.91-.589a4 4 0 0 1-5.501-5.501c.435-.71.244-1.91-.589-1.91Z",
    fill: e,
    fillRule: "evenodd"
  }), /* @__PURE__ */ c(h, {
    fill: e,
    height: 4,
    rx: 2,
    transform: "rotate(-45 1.373 43.799)",
    width: 60,
    x: 1.373,
    y: 43.799
  })]
}), S = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  viewBox: "0 0 48 48",
  ...l,
  children: [/* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M24 3C12.402 3 3 12.402 3 24s9.402 21 21 21 21-9.402 21-21S35.598 3 24 3Zm0 9c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12Z",
    fill: e,
    fillRule: "evenodd"
  }), /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M24 16a8 8 0 1 0 8 8c0-.832-1.2-1.024-1.91-.589a4 4 0 0 1-5.501-5.501c.435-.71.244-1.91-.589-1.91Z",
    fill: e,
    fillRule: "evenodd"
  })]
}), y = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  viewBox: "0 0 40 40",
  ...l,
  children: /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M3.333 18.333c0-4.984 0-7.477 1.072-9.333a8 8 0 0 1 2.928-2.928C9.19 5 11.682 5 16.667 5 21.65 5 24.144 5 26 6.072A8 8 0 0 1 28.928 9c.504.872.77 1.885.912 3.23l.214-.157c2.677-1.967 4.016-2.951 5.131-2.905.971.04 1.875.526 2.468 1.326.68.919.68 2.622.68 6.028v6.956c0 3.406 0 5.11-.68 6.028a3.243 3.243 0 0 1-2.468 1.326c-1.115.046-2.454-.938-5.131-2.905l-.214-.157c-.141 1.345-.408 2.358-.912 3.23A8 8 0 0 1 26 33.928C24.144 35 21.651 35 16.667 35c-4.985 0-7.477 0-9.334-1.072A8 8 0 0 1 4.405 31c-1.072-1.856-1.072-4.349-1.072-9.333v-3.334Zm16.667 0a3.333 3.333 0 1 0 0-6.666 3.333 3.333 0 0 0 0 6.666Z",
    fill: e,
    fillRule: "evenodd"
  })
}), f = /* @__PURE__ */ a(({
  fill: e = "#BCBCCC",
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...l,
  children: /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M7 2.5h10c2.76 0 5 2.231 5 4.982v6.984c0 2.751-2.24 4.982-5 4.982h-1.5c-.31 0-.61.15-.8.4l-1.5 1.992c-.66.88-1.74.88-2.4 0l-1.5-1.991c-.17-.22-.53-.4-.8-.4H7c-2.76 0-5-2.232-5-4.983V7.482C2 4.732 4.24 2.5 7 2.5ZM10.5 8a1 1 0 1 1 0 2h-3a1 1 0 1 1 0-2h3Zm3 4a1 1 0 1 1 0 2h-6a1 1 0 1 1 0-2h6Z",
    fill: e,
    fillRule: "evenodd"
  })
}));
f.displayName = "IconChat";
const C = /* @__PURE__ */ a(({
  fill: e = "#BCBCCC",
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...l,
  children: [/* @__PURE__ */ c(n, {
    d: "M20 7.25a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5Z",
    fill: "#F54522"
  }), /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M15.85 5.46a4.226 4.226 0 0 0 3.19 3.19c.61.13 1.2.13 1.76.03.62-.12 1.2.34 1.2.97v4.81c0 2.76-2.23 4.99-4.99 4.99H15.5a1 1 0 0 0-.8.4l-1.5 1.99c-.66.88-1.74.88-2.4 0l-1.5-1.99c-.16-.22-.52-.4-.8-.4H7c-2.76 0-5-2.24-5-5V7.5c0-2.76 2.24-5 5-5h7.85c.64 0 1.1.58.97 1.2-.11.56-.1 1.15.03 1.76ZM10.5 8a1 1 0 1 1 0 2h-3a1 1 0 0 1 0-2h3Zm3 4a1 1 0 1 1 0 2h-6a1 1 0 1 1 0-2h6Z",
    fill: e,
    fillRule: "evenodd"
  })]
}));
C.displayName = "IconChatWithSign";
const A = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  viewBox: "0 0 33 32",
  ...l,
  children: [/* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M16.927 2c-7.732 0-14 6.268-14 14s6.268 14 14 14 14-6.268 14-14-6.268-14-14-14Zm0 6a8 8 0 1 0 0 16 8 8 0 0 0 0-16Z",
    fill: e,
    fillRule: "evenodd"
  }), /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M16.927 10.667A5.333 5.333 0 1 0 22.26 16c0-.555-.8-.683-1.273-.392a2.667 2.667 0 0 1-3.668-3.667c.29-.474.163-1.274-.392-1.274Z",
    fill: e,
    fillRule: "evenodd"
  })]
}), H = ({
  fill: e = "#BCBCCC",
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  height: 32,
  viewBox: "0 0 32 32",
  width: 32,
  ...l,
  children: /* @__PURE__ */ c(n, {
    d: "m12 20 8-8m0 8-8-8",
    stroke: e,
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: 2
  })
}), L = ({
  fill: e = "#BCBCCC",
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...l,
  children: /* @__PURE__ */ c(n, {
    d: "M4 20 20 4M4 4l16 16",
    stroke: e,
    strokeLinecap: "round",
    strokeWidth: 2
  })
}), k = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  viewBox: "0 0 40 40",
  ...l,
  children: /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M20 2.5C10.335 2.5 2.5 10.335 2.5 20S10.335 37.5 20 37.5 37.5 29.665 37.5 20 29.665 2.5 20 2.5Zm2.155 12.988c.65-.65 1.706-.65 2.357 0l3.333 3.334c.322.322.485.742.488 1.165v.027a1.664 1.664 0 0 1-.475 1.152l-.014.014-3.332 3.332a1.667 1.667 0 1 1-2.357-2.357l.488-.488h-9.31a1.667 1.667 0 0 1 0-3.334h9.31l-.488-.488a1.667 1.667 0 0 1 0-2.357Z",
    fill: e,
    fillRule: "evenodd"
  })
}), P = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...l,
  children: /* @__PURE__ */ c(n, {
    d: "m8.707 19.707 8.586-8.586a1 1 0 0 0 0-1.414l-3-3a1 1 0 0 0-1.414 0l-8.586 8.586a1.003 1.003 0 0 0-.263.464l-.743 3.782a1 1 0 0 0 1.174 1.174l3.781-.743c.176-.044.337-.135.465-.263ZM21 7.414a2 2 0 0 0 0-2.828L19.414 3a2 2 0 0 0-2.828 0l-.879.879a1 1 0 0 0 0 1.414l3 3a1 1 0 0 0 1.414 0L21 7.414Z",
    fill: e
  })
}), z = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...l,
  children: /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M12.25 22.5h-.5c-3.771 0-5.657 0-6.828-1.172C3.75 20.157 3.75 18.271 3.75 14.5V7.834c0-1.708 0-2.562.256-3.243a4 4 0 0 1 2.335-2.335C7.022 2 7.876 2 9.584 2c.747 0 1.12 0 1.475.065a4 4 0 0 1 1.56.646c.297.205.561.47 1.09.998l4.198 4.198c1.156 1.156 1.734 1.734 2.038 2.47.305.734.305 1.552.305 3.187v.936c0 3.771 0 5.657-1.172 6.828C17.907 22.5 16.021 22.5 12.25 22.5Zm.293-17.767 5 5.25A.75.75 0 0 1 17 11.25h-5a.75.75 0 0 1-.75-.75V5.25a.75.75 0 0 1 1.293-.517Z",
    fill: e,
    fillRule: "evenodd"
  })
}), F = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  viewBox: "0 0 32 32",
  ...l,
  children: /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "m19.672 3.05 7.037 5.374c1.465 1.027 2.624 3.24 2.624 5.04v9.042a6.16 6.16 0 0 1-6.153 6.16H8.82c-3.396 0-6.153-2.773-6.153-6.173v-9.201c0-1.68 1.052-3.814 2.384-4.854l7.308-5.214c2.011-1.56 5.222-1.64 7.313-.174ZM16 24.667c.736 0 1.333-.597 1.333-1.334v-4a1.333 1.333 0 0 0-2.666 0v4c0 .737.597 1.334 1.333 1.334Z",
    fill: e,
    fillRule: "evenodd"
  })
}), W = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  viewBox: "0 0 28 28",
  ...l,
  children: [/* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M4.514 12.884a1.674 1.674 0 1 0-3.347 0V14c0 7.088 5.745 12.833 12.833 12.833S26.833 21.088 26.833 14v-1.116a1.674 1.674 0 1 0-3.348 0V14a9.486 9.486 0 0 1-18.971 0v-1.116Z",
    fill: e,
    fillRule: "evenodd"
  }), /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M14 1.167a7 7 0 0 0-7 7V14a7 7 0 1 0 14 0V8.167a7 7 0 0 0-7-7ZM14 4.5c-.986 0-1.907.297-2.674.806a1.333 1.333 0 0 0 1.477 2.22A2.15 2.15 0 0 1 14 7.167c.445 0 .855.133 1.197.36a1.333 1.333 0 0 0 1.477-2.22A4.817 4.817 0 0 0 14 4.5Z",
    fill: e,
    fillRule: "evenodd"
  }), /* @__PURE__ */ c(h, {
    fill: e,
    height: 2.333,
    rx: 1.167,
    transform: "rotate(-45 .8 25.55)",
    width: 35,
    x: 0.801,
    y: 25.549
  })]
}), E = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  viewBox: "0 0 24 24",
  ...l,
  children: [/* @__PURE__ */ c(n, {
    d: "M12 21.93c-5.04 0-9.15-4.1-9.15-9.15V10.9c0-.39.32-.7.7-.7.38 0 .7.32.7.7v1.88c0 4.27 3.47 7.74 7.74 7.74s7.74-3.47 7.74-7.74V10.9c0-.39.32-.7.7-.7.38 0 .7.32.7.7v1.88c.02 5.05-4.09 9.15-9.13 9.15Z",
    fill: e
  }), /* @__PURE__ */ c(n, {
    d: "M12 2C8.64 2 5.9 4.74 5.9 8.1v4.69c0 3.36 2.74 6.1 6.1 6.1 3.36 0 6.1-2.74 6.1-6.1V8.1C18.1 4.74 15.36 2 12 2Z",
    fill: e
  })]
}), M = /* @__PURE__ */ a(({
  fill: e,
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  viewBox: "0 0 33 32",
  ...l,
  children: [/* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M6.086 14.725a1.913 1.913 0 1 0-3.826 0V16c0 8.1 6.567 14.667 14.667 14.667S31.594 24.1 31.594 16v-1.275a1.913 1.913 0 0 0-3.827 0v1.585c-.075 5.785-4.745 10.456-10.53 10.53h-.62c-5.785-.074-10.456-4.745-10.53-10.53v-1.585Z",
    fill: e,
    fillRule: "evenodd"
  }), /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M16.927 1.333a8 8 0 0 0-8 8V16a8 8 0 1 0 16 0V9.333a8 8 0 0 0-8-8Zm0 4a5.315 5.315 0 0 0-2.95.89 1.333 1.333 0 1 0 1.476 2.22c.422-.28.927-.443 1.474-.443.547 0 1.052.163 1.474.444a1.333 1.333 0 1 0 1.476-2.22 5.315 5.315 0 0 0-2.95-.89Z",
    fill: e,
    fillRule: "evenodd"
  })]
}));
M.displayName = "IconMicro";
const N = ({
  fill: e = "#BCBCCC",
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  height: 19,
  viewBox: "0 0 18 19",
  width: 18,
  ...l,
  children: /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M6.294 19c-1.656 0-3.23-.666-4.432-1.875-2.388-2.403-2.486-6.213-.22-8.494l7.387-7.43A4.084 4.084 0 0 1 11.95 0c1.194 0 2.328.479 3.193 1.349 1.72 1.731 1.787 4.482.146 6.132L7.894 14.91a2.535 2.535 0 0 1-1.816.748 2.738 2.738 0 0 1-1.95-.821c-1.053-1.061-1.086-2.752-.073-3.772L10.88 4.21a.999.999 0 1 1 1.416 1.41l-6.823 6.856c-.24.242-.207.669.074.951a.76.76 0 0 0 .53.231.55.55 0 0 0 .399-.158l7.394-7.429c.868-.873.803-2.358-.145-3.312-.907-.912-2.447-.984-3.278-.148l-7.386 7.43c-1.494 1.503-1.396 4.049.22 5.674C4.104 16.544 5.174 17 6.294 17a3.648 3.648 0 0 0 2.61-1.064l7.387-7.43a1 1 0 1 1 1.418 1.41l-7.386 7.43C9.262 18.412 7.832 19 6.292 19",
    fill: e,
    fillRule: "evenodd"
  })
}), j = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  viewBox: "0 0 32 32",
  ...l,
  children: [/* @__PURE__ */ c(t, {
    clipPath: "url(#aIconPhone)",
    children: /* @__PURE__ */ c(n, {
      d: "M1.872 13.743A19.986 19.986 0 0 1 16.008 7.91a20.066 20.066 0 0 1 14.148 5.861 5.986 5.986 0 0 1 1.754 4.226 5.963 5.963 0 0 1-1.745 4.223 5.103 5.103 0 0 1-.771.642 4.914 4.914 0 0 1-.826.495 1.298 1.298 0 0 1-1.286-.093l-6.772-4.258a1.303 1.303 0 0 1-.607-1.085c-.01-.248-.01-.266.53-1.983a12.87 12.87 0 0 0-8.962-.027c.543 1.736.543 1.755.534 2.002a1.297 1.297 0 0 1-.605 1.083l-6.764 4.245a1.296 1.296 0 0 1-1.258.064 5.643 5.643 0 0 1-1.599-1.14A5.986 5.986 0 0 1 .09 17.93a5.962 5.962 0 0 1 1.783-4.188Z",
      fill: e
    })
  }), /* @__PURE__ */ c(r, {
    children: /* @__PURE__ */ c(s, {
      id: "aIconPhone",
      children: /* @__PURE__ */ c(n, {
        d: "M0 0h32v32H0z",
        fill: "#fff"
      })
    })
  })]
}), b = ({
  fill: e = "#fff",
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  height: 70,
  viewBox: "0 0 52 70",
  width: 52,
  ...l,
  children: [/* @__PURE__ */ c(n, {
    d: "m9.554 54.577-7.859 7.299C8.307 67.246 16.563 70 26.04 70c9.495 0 17.759-2.765 24.354-8.169L39.016 42.053l-9.545 5.53 5.662 9.843a.118.118 0 0 1-.034.156c-1.36.946-3.498 2.416-9.06 2.416-8.64 0-12.287-2.366-16.485-5.421Z",
    fill: e
  }), /* @__PURE__ */ c(n, {
    d: "M0 25.712c0 7.33 2.385 13.208 7.154 17.636 4.799 4.427 11.088 6.641 18.868 6.641 7.78 0 14.055-2.214 18.824-6.641C49.616 38.92 52 33.042 52 25.712v-1.436c0-7.359-2.385-13.237-7.154-17.635C40.076 2.214 33.802 0 26.022 0c-7.78 0-14.069 2.214-18.868 6.641C2.384 11.04 0 16.917 0 24.277v1.435Zm12.117-1.93c0-4.516 1.192-8.076 3.577-10.679 2.414-2.633 5.857-3.949 10.328-3.949 4.442 0 7.855 1.316 10.24 3.949 2.414 2.603 3.621 6.163 3.621 10.68v2.423c0 4.517-1.192 8.092-3.577 10.725-2.384 2.602-5.812 3.904-10.284 3.904-4.5 0-7.944-1.302-10.328-3.904-2.385-2.633-3.577-6.208-3.577-10.725v-2.423Z",
    fill: e
  })]
}), D = ({
  fill: e = "#fff",
  stroke: l = "#2770FF",
  ...v
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  height: 20,
  viewBox: "0 0 20 20",
  width: 20,
  ...v,
  children: [/* @__PURE__ */ c(i, {
    cx: 10,
    cy: 10,
    fill: l,
    r: 10
  }), /* @__PURE__ */ c(i, {
    cx: 10,
    cy: 10,
    fill: e,
    r: 4
  })]
}), U = ({
  fill: e = "#fff",
  stroke: l = "#E1E1EB",
  ...v
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  height: 20,
  viewBox: "0 0 20 20",
  width: 20,
  ...v,
  children: [/* @__PURE__ */ c(i, {
    cx: 10,
    cy: 10,
    r: 9.5,
    stroke: l
  }), /* @__PURE__ */ c(i, {
    cx: 10,
    cy: 10,
    fill: e,
    r: 4
  })]
}), m = /* @__PURE__ */ a(({
  fill: e,
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  height: 16,
  viewBox: "0 0 17 16",
  width: 17,
  ...l,
  children: /* @__PURE__ */ c(n, {
    d: "M8.334.667a.833.833 0 0 0-.833.833v5.867c-.217.02-.44.044-.667.073V2.167a.833.833 0 1 0-1.667 0v5.566c-.219.05-.441.103-.666.162V4.5a.833.833 0 1 0-1.667 0v6.326c0 2.49 2.462 4.507 5.5 4.507s5.667-2.018 5.667-4.507V6.145a.478.478 0 0 0-.479-.478c-.792 0-1.434.642-1.434 1.434v2.485h-.267v.006c-.577 0-1.309.034-1.953.278-.542.205-1.013.554-1.28 1.166-.075.169-.257.273-.434.222a.308.308 0 0 1-.212-.407c.344-.856.992-1.34 1.69-1.605.635-.24 1.318-.303 1.869-.317V2.167a.833.833 0 0 0-1.667 0V7.32c-.21-.013-.432-.02-.667-.023V1.5a.833.833 0 0 0-.833-.833Z",
    fill: e
  })
}));
m.displayName = "IconRaisedHand";
const G = ({
  fill: e = "#2768FF",
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...l,
  children: [/* @__PURE__ */ c(n, {
    d: "m8.803 4.319-3.21 3.21-1.97 1.96a2.13 2.13 0 0 0 0 3.01l5.18 5.18c.68.68 1.84.19 1.84-.76V5.079c0-.96-1.16-1.44-1.84-.76Z",
    fill: e
  }), /* @__PURE__ */ c(n, {
    d: "M13.5 7.5h-3v7h3.929a5 5 0 0 1 3.536 1.464l1.931 1.932a.353.353 0 0 0 .604-.25V14.5a7 7 0 0 0-7-7Z",
    fill: e
  })]
}), O = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  viewBox: "0 0 40 40",
  ...l,
  children: /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M13.333 2.5a2.5 2.5 0 0 0-2.5 2.5v1.284c-1.056.239-1.91.604-2.651 1.163a8 8 0 0 0-1.568 1.568C5 11.155 5 14.242 5 20.417c0 6.175 0 9.262 1.614 11.401a8.002 8.002 0 0 0 1.568 1.568C10.321 35 13.408 35 19.583 35h.834c6.175 0 9.262 0 11.401-1.614a8.004 8.004 0 0 0 1.568-1.568C35 29.679 35 26.592 35 20.417s0-9.263-1.614-11.402a8.002 8.002 0 0 0-1.568-1.568c-.74-.559-1.595-.924-2.651-1.163V5a2.5 2.5 0 0 0-5 0v.852c-1.108-.019-2.35-.019-3.75-.019h-.834c-1.4 0-2.642 0-3.75.02V5a2.5 2.5 0 0 0-2.5-2.5Zm.834 18.333a1.667 1.667 0 0 1 3.333 0V22.5h1.667a1.667 1.667 0 0 1 0 3.333H17.5V27.5a1.667 1.667 0 1 1-3.333 0v-1.667H12.5a1.667 1.667 0 1 1 0-3.333h1.667v-1.667Z",
    fill: e,
    fillRule: "evenodd"
  })
}), Q = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  viewBox: "0 0 48 48",
  ...l,
  children: [/* @__PURE__ */ c(h, {
    fill: e,
    height: 6,
    rx: 3,
    width: 40,
    x: 4,
    y: 38
  }), /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M4 19c0-6.575 0-9.862 1.816-12.075a8 8 0 0 1 1.109-1.11C9.138 4 12.425 4 19 4h10c6.575 0 9.862 0 12.075 1.816.405.332.777.704 1.11 1.109C44 9.138 44 12.425 44 19c0 6.575 0 9.862-1.816 12.075a8 8 0 0 1-1.109 1.11C38.862 34 35.575 34 29 34H19c-6.575 0-9.862 0-12.075-1.816a8.002 8.002 0 0 1-1.11-1.109C4 28.862 4 25.575 4 19Zm14.616-5.253a2 2 0 0 1 2.238-1.73c5.527.705 9.623 4.819 10.34 10.335a2 2 0 1 1-3.967.516c-.484-3.723-3.167-6.41-6.88-6.884a2 2 0 0 1-1.73-2.237Zm1.242 5.42a2 2 0 1 0-.516 3.966c.795.104 1.332.64 1.435 1.434a2 2 0 0 0 3.967-.514c-.338-2.605-2.3-4.55-4.886-4.886Z",
    fill: e,
    fillRule: "evenodd"
  })]
}), q = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  viewBox: "0 0 24 24",
  ...l,
  children: [/* @__PURE__ */ c(i, {
    cx: 10,
    cy: 10,
    r: 8,
    stroke: e,
    strokeWidth: 2
  }), /* @__PURE__ */ c(n, {
    d: "M19.533 21.467 17.467 19.4a1.367 1.367 0 1 1 1.933-1.933l2.067 2.066a1.367 1.367 0 0 1-1.934 1.934Z",
    fill: e
  })]
}), J = ({
  fill: e = "#BCBCCC",
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...l,
  children: /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "m9.218 3.365 9.564 4.757c4.29 2.133 4.29 5.623 0 7.756l-9.564 4.757c-6.435 3.2-9.061.577-5.843-5.812l.447-.884a2 2 0 0 1 1.66-1.094l5.019-.314a.532.532 0 0 0 0-1.062l-5.022-.314a2 2 0 0 1-1.662-1.098l-.442-.88C.157 2.787 2.794.165 9.218 3.365Z",
    fill: e,
    fillRule: "evenodd"
  })
}), u = /* @__PURE__ */ a(({
  fill: e,
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  viewBox: "0 0 32 32",
  ...l,
  children: /* @__PURE__ */ c(n, {
    d: "M25.586 6.785 18.445 2.6c-1.368-.799-3.509-.799-4.876 0l-7.21 4.214c-2.86 1.96-3.026 2.254-3.026 5.377v7.603c0 3.122.166 3.43 3.08 5.418l7.142 4.187c.69.406 1.575.602 2.445.602.87 0 1.754-.196 2.431-.602l7.21-4.215c2.86-1.96 3.026-2.254 3.026-5.376V12.19c0-3.123-.166-3.417-3.08-5.405ZM16 20.55c-2.473 0-4.49-2.044-4.49-4.55 0-2.507 2.017-4.551 4.49-4.551 2.473 0 4.49 2.044 4.49 4.55 0 2.507-2.017 4.55-4.49 4.55Z",
    fill: e
  })
}));
u.displayName = "IconSettings";
const K = (e) => /* @__PURE__ */ c(o, {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...e,
  children: /* @__PURE__ */ c(n, {
    d: "M12 16v2m4 2H8m8-9.5H8m8 0-3 3m3-3-3-3m7-.5V6a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4",
    stroke: "#001A72",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: 1.5
  })
}), T = ({
  fill: e = "#BCBCCC",
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  height: 24,
  viewBox: "0 0 24 24",
  width: 24,
  ...l,
  children: /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11Zm0-2a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm4.093-6.127c-.452-.318-1.07-.196-1.489.164A3.988 3.988 0 0 1 12 16a3.988 3.988 0 0 1-2.604-.963c-.42-.36-1.037-.482-1.489-.164-.451.318-.565.949-.177 1.342A5.988 5.988 0 0 0 12 18c1.635 0 3.16-.66 4.27-1.785.388-.393.274-1.024-.177-1.342ZM15.5 11a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3ZM7 9.5a1.5 1.5 0 1 0 3 0 1.5 1.5 0 0 0-3 0Z",
    fill: e,
    fillRule: "evenodd"
  })
}), X = /* @__PURE__ */ Z.memo((e) => /* @__PURE__ */ c(o, {
  height: 24,
  viewBox: "0 0 122.88 116.864",
  width: 24,
  ...e,
  children: /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "m61.44 0 16.911 41.326 44.529 3.312-34.077 28.853 10.609 43.373L61.44 93.371l-37.972 23.493 10.61-43.373L0 44.638l44.529-3.312L61.44 0z",
    fillRule: "evenodd"
  })
})), Y = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  viewBox: "0 0 24 24",
  ...l,
  children: /* @__PURE__ */ c(n, {
    d: "M12 19a7 7 0 1 0 0-14 7 7 0 0 0 0 14ZM12 22.96c-.55 0-1-.41-1-.96v-.08c0-.55.45-1 1-1s1 .45 1 1-.45 1.04-1 1.04Zm7.14-2.82c-.26 0-.51-.1-.71-.29l-.13-.13a.996.996 0 1 1 1.41-1.41l.13.13a.996.996 0 0 1-.7 1.7Zm-14.28 0c-.26 0-.51-.1-.71-.29a.996.996 0 0 1 0-1.41l.13-.13a.996.996 0 1 1 1.41 1.41l-.13.13c-.19.19-.45.29-.7.29ZM22 13h-.08c-.55 0-1-.45-1-1s.45-1 1-1 1.04.45 1.04 1-.41 1-.96 1ZM2.08 13H2c-.55 0-1-.45-1-1s.45-1 1-1 1.04.45 1.04 1-.41 1-.96 1Zm16.93-7.01c-.26 0-.51-.1-.71-.29a.996.996 0 0 1 0-1.41l.13-.13a.996.996 0 1 1 1.41 1.41l-.13.13c-.19.19-.44.29-.7.29Zm-14.02 0c-.26 0-.51-.1-.71-.29l-.13-.14a.996.996 0 1 1 1.41-1.41l.13.13c.39.39.39 1.02 0 1.41-.19.2-.45.3-.7.3ZM12 3.04c-.55 0-1-.41-1-.96V2c0-.55.45-1 1-1s1 .45 1 1-.45 1.04-1 1.04Z",
    fill: e
  })
}), _ = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  viewBox: "0 0 20 20",
  ...l,
  children: [/* @__PURE__ */ c(n, {
    d: "M7.5 1.25a4.173 4.173 0 0 0-4.167 4.171c0 2.257 1.763 4.083 4.062 4.162a.707.707 0 0 1 .193 0h.061a4.158 4.158 0 0 0 4.018-4.162c0-2.3-1.869-4.171-4.167-4.171ZM11.651 12.028c-2.394-1.593-6.299-1.593-8.71 0-1.09.728-1.691 1.713-1.691 2.766s.6 2.03 1.682 2.749c1.202.805 2.78 1.207 4.36 1.207 1.579 0 3.158-.402 4.36-1.207 1.08-.728 1.681-1.704 1.681-2.766-.008-1.054-.6-2.03-1.682-2.75ZM17.072 5.775c.147 1.69-1.122 3.17-2.877 3.37h-.046c-.055 0-.11 0-.157.017-.891.044-1.71-.226-2.325-.723a3.879 3.879 0 0 0 1.379-3.308 3.905 3.905 0 0 0-.708-1.898c.349-.165.754-.27 1.167-.305 1.802-.148 3.41 1.123 3.567 2.847Z",
    fill: e
  }), /* @__PURE__ */ c(n, {
    d: "M18.74 13.941c-.07.836-.619 1.56-1.538 2.051-.884.474-1.999.698-3.104.673.637-.56 1.008-1.258 1.079-2 .088-1.068-.433-2.094-1.477-2.912a7.357 7.357 0 0 0-2.033-1.086c1.954-.552 4.412-.181 5.924 1.008.813.638 1.229 1.44 1.15 2.266Z",
    fill: e
  })]
}), $ = ({
  fill: e,
  ...l
}) => /* @__PURE__ */ c(o, {
  fill: "none",
  viewBox: "0 0 33 32",
  ...l,
  children: /* @__PURE__ */ c(n, {
    clipRule: "evenodd",
    d: "M16.927 2c-7.732 0-14 6.268-14 14 0 4.259 1.901 8.073 4.902 10.64.641.55 1.764-.459 1.764-1.303 0-1.597.73-3.09 2.052-4.193 2.927-2.414 7.667-2.414 10.573 0 1.313 1.09 2.032 2.57 2.042 4.167 0 .854 1.133 1.872 1.781 1.316A13.968 13.968 0 0 0 30.927 16c0-7.732-6.268-14-14-14Zm0 6.667c-2.207 0-4 1.795-4 4.004 0 2.136 1.646 3.871 3.807 3.992.06.003.122-.002.182-.003.019 0 .036 0 .053.002.028.002.056.005.084.005h.017a3.992 3.992 0 0 0 3.857-3.996 4.006 4.006 0 0 0-4-4.004Z",
    fill: e,
    fillRule: "evenodd"
  })
}), R = /* @__PURE__ */ a((e) => /* @__PURE__ */ d(o, {
  height: 24,
  viewBox: "0 0 487.875 487.875",
  width: 24,
  ...e,
  children: [/* @__PURE__ */ c(n, {
    d: "M262.503 320.275c-5.5-8.5-22.4-16.3-35.4 0l-83.3 136.4c-5.3 8.5-1.2 31.2 17.7 31.2h166.6c11.9 0 28-13.9 16.7-33.3l-82.3-134.3zm-64.6 127 46.9-77.1 46.9 77.1h-93.8zM436.403 76.575c-106.3-102.1-279.1-102.1-385.3 0-7.3 8.3-8.3 20.8 0 29.2 8.3 7.3 20.8 8.3 29.2 0 89.6-87.5 237.4-87.5 328 0 4.2 3.1 16.5 9.6 28.1 0 8.3-8.4 8.3-20.9 0-29.2z"
  }), /* @__PURE__ */ c(n, {
    d: "M129.203 143.175c-7.3 8.3-8.3 20.8 0 29.2 8.3 7.3 20.8 8.3 29.2 0 47.9-45.8 125-45.8 171.8 0 4.2 3.1 15.9 9.9 27.1 0 8.3-8.3 8.3-20.8 0-29.2-62.6-60.4-165.7-60.4-228.1 0zM222.903 243.175c11.5-11.5 31.2-11.5 42.7 0 12.6 9.8 25 4.3 28.1 0 8.3-8.3 8.3-20.8 0-29.2-28.1-26-72.9-26-100 0-7.3 8.3-8.3 20.8 0 29.2 8.4 7.3 20.8 7.3 29.2 0z"
  })]
}));
R.displayName = "IconWifi";
const e1 = ({
  fill: e = "#000",
  ...l
}) => /* @__PURE__ */ d(o, {
  fill: "none",
  height: 40,
  viewBox: "0 0 84 40",
  width: 84,
  ...l,
  children: [/* @__PURE__ */ d(t, {
    clipPath: "url(#logosvg)",
    children: [/* @__PURE__ */ c(n, {
      d: "m39.79 32.283-3.535 3.27c2.974 2.407 6.687 3.641 10.949 3.641 4.27 0 7.987-1.24 10.953-3.66L53.04 26.67l-4.292 2.478 2.546 4.41a.053.053 0 0 1-.016.07c-.611.424-1.572 1.083-4.074 1.083-3.886 0-5.526-1.06-7.414-2.43Z",
      fill: e
    }), /* @__PURE__ */ c(n, {
      d: "M35.493 19.348c0 3.284 1.073 5.919 3.218 7.903 2.158 1.984 4.986 2.976 8.485 2.976 3.5 0 6.321-.992 8.466-2.976 2.145-1.985 3.218-4.619 3.218-7.903v-.644c0-3.297-1.073-5.932-3.218-7.902-2.145-1.984-4.967-2.976-8.466-2.976-3.498 0-6.327.992-8.485 2.976-2.145 1.97-3.218 4.605-3.218 7.902v.644Zm5.45-.865c0-2.024.536-3.62 1.608-4.786 1.086-1.18 2.635-1.77 4.645-1.77 1.998 0 3.533.59 4.605 1.77 1.086 1.167 1.63 2.762 1.63 4.786v1.086c0 2.024-.537 3.626-1.61 4.806-1.072 1.166-2.614 1.75-4.625 1.75-2.024 0-3.572-.584-4.645-1.75-1.072-1.18-1.608-2.782-1.608-4.806v-1.086ZM25.391 29.623h5.35V8.43h-5.35v21.194ZM0 23.048v.201c0 2.185.885 3.895 2.654 5.128 1.77 1.233 4.304 1.85 7.601 1.85 3.325 0 5.845-.617 7.561-1.85 1.73-1.233 2.594-2.876 2.594-4.927 0-1.836-.663-3.264-1.99-4.283-1.314-1.019-3.372-1.776-6.174-2.272-2.694-.496-4.437-.945-5.228-1.348-.778-.415-1.166-.965-1.166-1.649 0-.737.335-1.307 1.005-1.709.684-.415 1.783-.623 3.298-.623 1.542-.014 2.668.234 3.378.744.724.51 1.086 1.22 1.086 2.131v.382h5.248v-.281c0-2.051-.878-3.687-2.634-4.907-1.756-1.22-4.115-1.83-7.078-1.83-2.91.014-5.228.61-6.958 1.79C1.481 10.761.623 12.31.623 14.24c0 1.743.677 3.124 2.031 4.143 1.354 1.005 3.399 1.756 6.134 2.252 2.734.51 4.464.965 5.188 1.367.737.402 1.105 1.006 1.105 1.81 0 .831-.422 1.475-1.266 1.93-.832.456-2.025.684-3.58.684-1.515 0-2.728-.248-3.64-.744-.898-.51-1.347-1.293-1.347-2.352v-.262L0 23.048ZM63.5 29.624h5.328V15.527c.537-1.032 1.274-1.85 2.212-2.453.952-.603 2.011-.905 3.177-.905 1.421 0 2.514.349 3.278 1.046.778.684 1.166 1.843 1.166 3.479v12.93h5.33v-12.79c0-3.244-.738-5.523-2.213-6.837-1.474-1.327-3.418-1.99-5.831-1.99-1.528 0-2.916.288-4.163.864-1.246.563-2.259 1.354-3.036 2.373h-.08l-.161-2.815h-5.008v21.195Z",
      fill: e
    }), /* @__PURE__ */ c(i, {
      cx: 56.949,
      cy: 4.023,
      fill: "#F54522",
      r: 3.217
    })]
  }), /* @__PURE__ */ c(r, {
    children: /* @__PURE__ */ c(s, {
      id: "logosvg",
      children: /* @__PURE__ */ c(n, {
        d: "M0 0h84v40H0z",
        fill: "#fff"
      })
    })
  })]
});
export {
  n1 as IconArrowLeft,
  o1 as IconArrowRight,
  B as IconCalendar,
  y as IconCamera,
  p as IconCameraChange,
  g as IconCameraDisabled,
  S as IconCameraRound,
  V as IconCameraRoundOff,
  f as IconChat,
  C as IconChatWithSign,
  d1 as IconCheck,
  A as IconCircleCamera,
  L as IconClose,
  H as IconCloseCircle,
  k as IconConnect,
  i1 as IconCopy,
  a1 as IconCrown,
  P as IconEdit,
  h1 as IconEye,
  v1 as IconEyeSlash,
  z as IconFolder,
  F as IconHome,
  M as IconMicro,
  W as IconMicroDisabled,
  E as IconMicrophone,
  t1 as IconMore,
  N as IconPaperClip,
  j as IconPhone,
  r1 as IconPlus,
  b as IconQ,
  D as IconRadioChecked,
  U as IconRadioUnchecked,
  m as IconRaisedHand,
  G as IconReply,
  O as IconSchedule,
  Q as IconScreenShare,
  q as IconSearch,
  J as IconSend,
  u as IconSettings,
  K as IconShareScreen,
  T as IconSmile,
  Y as IconSun,
  $ as IconUser,
  _ as IconUserGroup,
  R as IconWifi,
  e1 as Logo,
  X as ReactComponent
};
