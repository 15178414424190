import { extendTheme } from "native-base";

/**
 * white: white
 * text1: black
 * text2: text.200
 * text3: text.100
 * text4: text.50
 * brand1: red.50
 * redHover: red.100
 * brand2: blue.50
 * blueHover: blue.100
 * green: green.50
 * grey1: gray.50
 * grey2: gray.100
 * grey3: gray.200
 * grey4: gray.300
 * icon2: gray.400
 * icon1: gray.500
 */

const colors = {
  blue: {
    100: "#2768FF",
    50: "#2770FF",
  },
  gray: {
    100: "#EBEBF5",
    200: "#E1E1EB",
    300: "#D3D3E0",
    400: "#BCBCCC",
    50: "#F5F5FA",
    500: "#88889A",
  },
  green: {
    50: "#1BC47D",
  },
  opaque: { black: "rgba(0,0,0,0.4)" },
  red: {
    100: "#F53E22",
    50: "#F54522",
  },
  text: {
    100: "#707080",
    200: "#3D3D4D",
    50: "#A4A4B3",
  },
};

const shadows = {
  "1": {
    elevation: 2,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      height: 4,
      width: 0,
    },
    shadowOpacity: 0.04,
    shadowRadius: 2,
  },
  "2": {
    elevation: 16,
    shadowColor: "rgba(0, 0, 0, 1)",
    shadowOffset: {
      height: 8,
      width: 0,
    },
    shadowOpacity: 0.14,
    shadowRadius: 16,
  },
};

const Menu = {
  baseStyle: {
    borderRadius: 3,
    shadow: 2,
  },
};

const Radio = {
  baseStyle: {
    _checked: {
      borderColor: "blue.50",
      borderWidth: 6,
      p: "4px",
    },
    _focus: {
      borderColor: "blue.50",
      borderWidth: 6,
      p: "4px",
      shadow: "none",
    },
    _hover: {
      borderColor: "blue.50",
      borderWidth: 6,
      p: "4px",
      shadow: "none",
    },
    _pressed: {
      borderColor: "blue.50",
      borderWidth: 6,
      p: "4px",
      shadow: "none",
    },
    bg: "white",
    borderColor: "gray.200",
    borderWidth: 1,
    p: "9px",
  },
};

const Pressable = {
  baseStyle: {
    _focusVisible: {
      _web: {
        style: {
          boxShadow: "none",
          outlineWidth: 0,
        },
      },
    },
  },
};

export const DEFAULT_THEME = extendTheme({
  colors,
  components: {
    Menu,
    Pressable,
    Radio,
    Select: {
      baseStyle: {
        _dark: { color: "white", placeholderTextColor: "white" },
      },
    },
    SelectItem: {
      baseStyle: {
        _dark: {
          bg: "black",
        },
      },
    },
  },
  config: {
    initialColorMode: "light",
  },
  fontConfig: {
    "Roboto Flex": {
      100: {
        italic: "Roboto Flex",
        normal: "Roboto Flex",
      },
      200: {
        italic: "Roboto Flex",
        normal: "Roboto Flex",
      },
      300: {
        italic: "Roboto Flex",
        normal: "Roboto Flex",
      },
      400: {
        italic: "Roboto Flex",
        normal: "Roboto Flex",
      },
      500: {
        italic: "Roboto Flex",
        normal: "Roboto Flex",
      },
      600: {
        italic: "Roboto Flex",
        normal: "Roboto Flex",
      },
      700: {
        italic: "Roboto Flex",
        normal: "Roboto Flex",
      },
      800: {
        italic: "Roboto Flex",
        normal: "Roboto Flex",
      },
      900: {
        italic: "Roboto Flex",
        normal: "Roboto Flex",
      },
    },
  },
  fonts: {
    body: "Roboto Flex",
    heading: "Roboto Flex",
  },
  shadows,
});

// 2. Get the type of the CustomTheme
type CustomThemeType = typeof DEFAULT_THEME;

// 3. Extend the internal NativeBase Theme
declare module "native-base" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ICustomTheme extends CustomThemeType {}
}
