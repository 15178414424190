import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { useUnit } from "effector-react";
import { lazy, memo, Suspense } from "react";

import { $anonymousNickname, $isLoggedIn } from "@sign/shared/model";
import { RootStackParamList } from "~/shared/config/navigation";

// eslint-disable-next-line react-memo/require-memo
const AuthScreen = lazy(() => import("~/pages/Auth"));
// eslint-disable-next-line react-memo/require-memo
const NicknameScreen = lazy(() => import("~/pages/Auth/nickname-screen"));
// eslint-disable-next-line react-memo/require-memo
const MainScreen = lazy(() => import("~/pages/Main"));
// eslint-disable-next-line react-memo/require-memo
const CallRoomScreen = lazy(() => import("~/pages/CallRoomScreen"));

const Stack = createNativeStackNavigator<RootStackParamList>();

const DEFAULT_SCREEN_OPTIONS = { title: "Sign" };
export const StackNavigator = memo(() => {
  const isLoggedIn = useUnit($isLoggedIn);
  const anonymousNickname = useUnit($anonymousNickname);

  return (
    <Suspense>
      <Stack.Navigator
        initialRouteName={isLoggedIn ? "Main" : "Auth"}
        screenOptions={DEFAULT_SCREEN_OPTIONS}
      >
        {isLoggedIn ? (
          <Stack.Screen
            component={MainScreen}
            name="Main"
            options={{ headerShown: false }}
          />
        ) : (
          <Stack.Screen
            component={AuthScreen}
            name="Auth"
            options={{
              headerShown: false,
            }}
          />
        )}
        <Stack.Screen
          /* @ts-expect-error Сложно затипизировать*/
          component={
            !isLoggedIn && anonymousNickname == null
              ? NicknameScreen
              : CallRoomScreen
          }
          name="CallRoomScreen"
          options={{
            headerShown: false,
            title: "Встреча",
          }}
        />
      </Stack.Navigator>
    </Suspense>
  );
});
