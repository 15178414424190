import { ApolloProvider } from "@apollo/client";
import { config as gluestackConfig } from "@gluestack-ui/config";
import { GluestackUIProvider, Text } from "@gluestack-ui/themed";
import { NavigationContainer } from "@react-navigation/native";
import { subscribeToUserEventsFx } from "@sign/meetspace";
import {
  INativebaseConfig,
  NativeBaseProvider,
  ToastProvider,
  View,
} from "native-base";
import React from "react";
import { StyleSheet } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";

import { getApolloClient } from "@sign/shared/api";
import { linking } from "~/shared/config/navigation";
import { DEFAULT_THEME } from "~/shared/config/themes";

import { StackNavigator } from "~/app/config/StackNavigator";

subscribeToUserEventsFx();

const config: INativebaseConfig = {
  // rest of the config keys like dependencies can go here
  strictMode: "warn",
};
// eslint-disable-next-line react-memo/require-memo
const App = () => (
  <GluestackUIProvider config={gluestackConfig}>
    <ApolloProvider client={getApolloClient()}>
      <SafeAreaProvider style={styles.safeAreaProvider}>
        <NativeBaseProvider config={config} theme={DEFAULT_THEME}>
          <ToastProvider>
            <View bg="gray.200" flex="1">
              <NavigationContainer linking={linking}>
                <StackNavigator />
              </NavigationContainer>
            </View>
          </ToastProvider>
        </NativeBaseProvider>
      </SafeAreaProvider>
    </ApolloProvider>
  </GluestackUIProvider>
);

const styles = StyleSheet.create({ safeAreaProvider: { flex: 1 } });

// eslint-disable-next-line import/no-default-export
export default App;
