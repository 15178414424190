// import "@formatjs/intl-pluralrules/polyfill";

// import "@formatjs/intl-pluralrules/locale-data/ru";
// import "@formatjs/intl-pluralrules/locale-data/en";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translate_en from "~/shared/locales/en/translate.json";
import translate_ru from "~/shared/locales/ru/translate.json";

// eslint-disable-next-line import/no-named-as-default-member
void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    debug: true,
    fallbackLng: "ru",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: true, // process.env.NODE_ENV !== 'test'
    },
    resources: {
      en: { translation: translate_en },
      ru: { translation: translate_ru },
    },
  });
