import { attachLogger } from "effector-logger";

import { $refreshToken, persistLS } from "@sign/shared/model";
// import { inspectGraph, type Declaration } from "effector/inspect";

/* Мы сохраняем токен в localstorage только в основном приложении. */
persistLS({
  store: $refreshToken,
});

if (process.env.VITE_EFFECTOR_LOG === "true") {
  attachLogger();

  // function printDeclaration(d: Declaration) {
  //   console.log(`printDeclaration: ${d.kind} ${d.name}`);
  // }

  // inspectGraph({
  //   fn: (d) => {
  //     printDeclaration(d);
  //   },
  // });
}
