import h, { Path as n, Rect as i } from "react-native-svg";
import { jsx as o, jsxs as d } from "react/jsx-runtime";
import { memo as a } from "react";
const v = ({
  fill: c,
  ...e
}) => /* @__PURE__ */ o(h, {
  fill: "none",
  height: 16,
  viewBox: "0 0 17 16",
  width: 17,
  ...e,
  children: /* @__PURE__ */ o(n, {
    d: "m9.82 3.547-2.14 2.14-1.313 1.306a1.42 1.42 0 0 0 0 2.007l3.453 3.453c.453.454 1.227.127 1.227-.506V4.053c0-.64-.774-.96-1.227-.506Z",
    fill: c
  })
}), w = ({
  fill: c,
  ...e
}) => /* @__PURE__ */ o(h, {
  fill: "none",
  height: 16,
  viewBox: "0 0 17 16",
  width: 17,
  ...e,
  children: /* @__PURE__ */ o(n, {
    d: "M10.633 6.993 9.32 5.68 7.18 3.54a.721.721 0 0 0-1.227.513v7.894c0 .64.774.96 1.227.506L10.633 9a1.42 1.42 0 0 0 0-2.007Z",
    fill: c
  })
}), x = ({
  fill: c,
  ...e
}) => /* @__PURE__ */ o(h, {
  fill: "none",
  viewBox: "0 0 20 20",
  ...e,
  children: /* @__PURE__ */ o(n, {
    d: "M16.837 5.437 9.8 15.415A1.416 1.416 0 0 1 8.638 16a1.45 1.45 0 0 1-1.078-.47l-4.328-4.788c-.346-.383-.3-.944.105-1.28.393-.325 1.054-.332 1.456-.012l3.678 2.919 6.582-7.986c.327-.398 1.006-.505 1.47-.23.483.285.62.85.314 1.284Z",
    fill: c
  })
}), Z = ({
  fill: c,
  ...e
}) => /* @__PURE__ */ d(h, {
  fill: "none",
  viewBox: "0 0 20 20",
  ...e,
  children: [/* @__PURE__ */ o(n, {
    d: "M6.716 5H7.5c3.01 0 4.514 0 5.595.722.468.313.87.715 1.183 1.183C15 7.985 15 9.49 15 12.5v.784c.815-.061 1.384-.2 1.852-.512.364-.244.676-.556.92-.92.561-.84.561-2.011.561-4.352 0-2.34 0-3.511-.561-4.352a3.334 3.334 0 0 0-.92-.92c-.84-.561-2.011-.561-4.352-.561-2.34 0-3.511 0-4.352.561a3.333 3.333 0 0 0-.92.92c-.312.468-.45 1.037-.512 1.853Z",
    fill: c
  }), /* @__PURE__ */ o(i, {
    fill: c,
    height: 11.667,
    rx: 4,
    width: 11.667,
    x: 1.667,
    y: 6.667
  })]
}), l = /* @__PURE__ */ a(({
  fill: c,
  ...e
}) => /* @__PURE__ */ o(h, {
  viewBox: "0 0 640 512",
  ...e,
  children: /* @__PURE__ */ o(n, {
    d: "M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z",
    fill: c
  })
}));
l.displayName = "IconCrown";
const m = ({
  fill: c,
  ...e
}) => /* @__PURE__ */ d(h, {
  fill: "none",
  viewBox: "0 0 20 20",
  ...e,
  children: [/* @__PURE__ */ o(n, {
    d: "M17.725 7.65c-.242-.383-.5-.742-.767-1.075a.834.834 0 0 0-1.241-.075l-2.5 2.5c.183.55.216 1.183.05 1.842a3.35 3.35 0 0 1-2.417 2.416 3.267 3.267 0 0 1-1.842-.05l-2.05 2.05c-.416.417-.283 1.15.275 1.367a7.683 7.683 0 0 0 2.767.517c1.483 0 2.925-.434 4.242-1.242 1.341-.833 2.55-2.058 3.525-3.617.791-1.258.75-3.375-.042-4.633ZM11.683 8.317l-3.366 3.366a2.418 2.418 0 0 1-.7-1.683A2.388 2.388 0 0 1 10 7.617c.65 0 1.25.275 1.683.7Z",
    fill: c
  }), /* @__PURE__ */ o(n, {
    d: "m15.208 4.792-2.825 2.825A3.322 3.322 0 0 0 10 6.633 3.363 3.363 0 0 0 6.633 10c0 .933.375 1.775.984 2.383L4.8 15.208h-.008c-.925-.75-1.775-1.708-2.5-2.841-.834-1.309-.834-3.434 0-4.742.966-1.517 2.15-2.708 3.466-3.525C7.075 3.3 8.517 2.858 10 2.858c1.858 0 3.658.684 5.208 1.934ZM12.383 10A2.388 2.388 0 0 1 10 12.383c-.05 0-.092 0-.142-.016l2.509-2.509c.016.05.016.092.016.142Z",
    fill: c
  }), /* @__PURE__ */ o(n, {
    d: "M18.142 1.858a.644.644 0 0 0-.909 0L1.858 17.242a.644.644 0 0 0 0 .908.632.632 0 0 0 .9-.008L18.142 2.758a.62.62 0 0 0 0-.9Z",
    fill: c
  })]
}), M = ({
  fill: c,
  ...e
}) => /* @__PURE__ */ d(h, {
  fill: "none",
  viewBox: "0 0 20 20",
  ...e,
  children: [/* @__PURE__ */ o(n, {
    d: "M17.708 7.625C15.783 4.6 12.967 2.858 10 2.858c-1.483 0-2.925.434-4.242 1.242-1.316.817-2.5 2.008-3.466 3.525-.834 1.308-.834 3.433 0 4.742C4.217 15.4 7.033 17.133 10 17.133c1.483 0 2.925-.433 4.242-1.241 1.316-.817 2.5-2.009 3.466-3.525.834-1.3.834-3.434 0-4.742ZM10 13.367A3.363 3.363 0 0 1 6.633 10c0-1.858 1.5-3.367 3.367-3.367A3.363 3.363 0 0 1 13.367 10c0 1.858-1.5 3.367-3.367 3.367Z",
    fill: c
  }), /* @__PURE__ */ o(n, {
    d: "M10 7.617a2.38 2.38 0 0 0 0 4.758A2.386 2.386 0 0 0 12.383 10 2.394 2.394 0 0 0 10 7.617Z",
    fill: c
  })]
}), I = ({
  fill: c,
  ...e
}) => /* @__PURE__ */ d(h, {
  fill: "none",
  viewBox: "0 0 20 20",
  ...e,
  children: [/* @__PURE__ */ o(i, {
    fill: c,
    height: 5,
    rx: 2.5,
    width: 5,
    x: 7.5,
    y: 0.833
  }), /* @__PURE__ */ o(i, {
    fill: c,
    height: 5,
    rx: 2.5,
    width: 5,
    x: 7.5,
    y: 7.5
  }), /* @__PURE__ */ o(i, {
    fill: c,
    height: 5,
    rx: 2.5,
    width: 5,
    x: 7.5,
    y: 14.167
  })]
}), g = ({
  fill: c = "#2768FF",
  ...e
}) => /* @__PURE__ */ o(h, {
  fill: "none",
  height: 40,
  viewBox: "0 0 40 40",
  width: 40,
  ...e,
  children: /* @__PURE__ */ o(n, {
    clipRule: "evenodd",
    d: "M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20Zm26-2a2 2 0 1 1 0 4h-4v4a2 2 0 1 1-4 0v-4h-4a2 2 0 1 1 0-4h4v-4a2 2 0 1 1 4 0v4h4Z",
    fill: c,
    fillRule: "evenodd"
  })
});
export {
  v as I,
  w as a,
  x as b,
  Z as c,
  l as d,
  m as e,
  M as f,
  I as g,
  g as h
};
